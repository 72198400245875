<template>
  <div>
    <b-tabs>
      <b-tab title="主体信息">
        <supplier-entity :company_id="company_id" v-on:push_company_id="getCompanyId($event)"></supplier-entity>
      </b-tab>

      <b-tab title="联系人信息">
        <supplier-contact :company_id="company_id"></supplier-contact>
      </b-tab>

      <b-tab title="银行账户">
        <supplier-bak-acc :company_id="company_id"></supplier-bak-acc>
      </b-tab>

<!--      <b-tab title="账期信息">-->
<!--        <supplier-warehouse-statement :company_id="company_id"></supplier-warehouse-statement>-->
<!--      </b-tab>-->

      <b-tab title="证照附件">
        <supplier-attachments :company_id="company_id"></supplier-attachments>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import supplierEntity from "@/views/apps/company-new/supplier/SupplierEntity";
import supplierContact from "@/views/apps/company-new/supplier/SupplierContact";
import supplierBakAcc from "@/views/apps/company-new/supplier/SupplierBakAcc";
import supplierWarehouseStatement from "@/views/apps/company-new/supplier/SupplierWarehouseStatement";
import supplierAttachments from "@/views/apps/company-new/supplier/SupplierAttachments";
import {getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from "@core/utils/filter";
import {ref} from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    supplierEntity,
    supplierContact,
    supplierBakAcc,
    supplierWarehouseStatement,
    supplierAttachments,
  },
  data() {
    return {
      company_id:ref(0),
    }
  },
  setup() {
    const getCompanyId = function (param){
      this.company_id = param
    }

    return {
      getCompanyId,
    }
  },
  created() {
    this.company_id = Number.parseInt(this.$route.query.id) || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
