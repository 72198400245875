<template>
  <b-card>
    <b-form id="customerBakAccForm" class="edit-form mt-2">

      <!--基本信息-->
      <b-col md="12">
        <b-card header="基本信息">
          <b-row>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="结算方式"
                  label-for="statement_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="statement_type"
                    :options="getCodeOptions('statement_type')"
                    @input="changeSelect('statement_type',$event)"
                    v-model="statement_type"
                    class="select-size-sm"
                    placeholder="请选择结算方式"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="结算日期"
                  label-for="statement_day"
                  label-size="sm"
                  class="mb-1"
              >
                <flat-pickr
                    id="registration_date"
                    v-model="warehousestatement.statement_day"
                    class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="到账周期"
                  label-for="statement_over"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="statement_over"
                    size="sm"
                    type="number"
                    v-model="warehousestatement.statement_over"
                    placeholder="天"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="付款周期"
                  label-for="pay_period"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="pay_period"
                    :options="getCodeOptions('preorder_pay_preiod')"
                    @input="changeSelect('pay_period',$event)"
                    v-model="pay_period"
                    class="select-size-sm"
                    placeholder="请选择付款周期"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="memo"
                  label-size="sm"
                  :class="`mb-1 ${this.warehousestatement.pay_period==6 ? 'required' : ''}`"
              >
                <b-form-input
                    id="memo"
                    size="sm"
                    v-model="warehousestatement.memo"
                />
              </b-form-group>
            </b-col>


            <b-col
                cols="12"
                class="mt-50"
            >
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="save"
              >
                保存
              </b-button>
              <b-button
                  variant="outline-secondary"
                  @click="cancel"
              >
                取消
              </b-button>
              </b-col>
          </b-row>
        </b-card>
      </b-col>


    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty,
  second,
} from '@core/utils/filter'
import warehousestatementStore from "@/views/apps/warehousestatement/warehousestatementStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
  },
  data() {
    return {
      warehousestatement: {},
      statement_type:null,
      pay_period:null,
    }
  },
  props:{
    company_id:Number,
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousestatementstore')) store.registerModule('warehousestatementstore', warehousestatementStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatementstore')) store.unregisterModule('warehousestatementstore')
    })

    const edit = function () {
      store.dispatch('warehousestatementstore/editNew', {company_id: props.company_id}).then(res => {
        this.warehousestatement = res.data.data
        if(this.warehousestatement.id != 0){
          this.statement_type = getCode('statement_type',this.warehousestatement.statement_type)
          this.pay_period = getCode('preorder_pay_preiod',this.warehousestatement.pay_period)
          this.warehousestatement.statement_day = toDate(this.warehousestatement.statement_day)
        }
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      let flag = true
      if(isEmpty(this.warehousestatement.statement_type)){
        toast.error('请选择 结算方式')
        flag = false
      }
      if(isEmpty(this.warehousestatement.pay_period)){
        toast.error('请选择 付款周期')
        flag = false
      }
      if(this.warehousestatement.pay_period == 6 && isEmpty(this.warehousestatement.memo)){
        toast.error('请填写 备注')
        flag = false
      }
      if(props.company_id == 0){
        toast.error('请先填写 主体信息')
        flag = false
      }

      if(!flag) return

      this.warehousestatement.company_id = props.company_id
      this.warehousestatement.statement_day = second(this.warehousestatement.statement_day)
      axios.post('api/warehousestatement/save',this.warehousestatement).then(res =>{
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeSelect = function (key,param){
      if(!isEmpty(param)){
        this.warehousestatement[key] = param.value
      }else {
        this.warehousestatement[key] = null
      }
      this.$forceUpdate()
    }


    return {
      edit,
      cancel,
      save,
      changeSelect,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      Number,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
